import { FormikErrors } from 'formik';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { useCallback, useEffect, useMemo } from 'react';

import StoreService from './StoreService';

import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents } from '@entities/enums';

interface TrackProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type FormErrors = FormikErrors<object>;

interface CustomMixpanel extends Mixpanel {
  trackFormErrors: (errors: FormErrors) => void;
}

export const useCustomMixpanel = (): CustomMixpanel => {
  const mixpanel = useMixpanel();
  const { getCurrentPage, promo } = useAppContext();
  const store = StoreService.load();
  const track = useCallback(
    (eventName: string, properties: TrackProperties) => {
      mixpanel.track(eventName, {
        accountId: store.accountId,
        promoCode: promo,
        pageName: getCurrentPage(),
        coverType: store.selectedCoverType,
        excessSelected: store.excess,
        source: store.tracking.source,
        ...properties,
      });
    },
    [
      getCurrentPage,
      mixpanel,
      promo,
      store.accountId,
      store.selectedCoverType,
      store.tracking.source,
      store.excess,
    ]
  );

  const trackFormErrors = useCallback(
    (errors: FormErrors) => {
      Object.entries(errors).map(([field, error]) => {
        track(MixpanelEvents.ERROR, {
          field,
          error,
        });
      });
    },
    [track]
  );

  return useMemo(
    () => ({
      ...mixpanel,
      track,
      trackFormErrors,
    }),
    [mixpanel, track, trackFormErrors]
  );
};

export const MixpanelProvider: React.FC = ({ children }) => {
  const mixpanel = useMixpanel();
  mixpanel.identify();
  const store = StoreService.load();

  const extraProperties = useMemo(() => {
    return {
      coverType: store.selectedCoverType,
      accountId: store.accountId,
      excessSelected: store.excess,
      creditType: store.creditType,
      tariffName: store.tariffName,
    };
  }, [store.selectedCoverType, store.accountId, store.excess, store.creditType, store.tariffName]);

  useEffect(() => {
    mixpanel.people.set({ ...extraProperties });
  }, [mixpanel.people, extraProperties]);

  return <>{children}</>;
};
