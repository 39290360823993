import { ProductName, Promo } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import StoreService from '@services/StoreService';
import {
  energyRefixFreeAbsPromoCodes,
  q1HalfPriceAbsPromoCodes,
  q2HalfPriceAbsPromoCodes,
} from '@constants/promotions';
import PromoService from '@services/PromoService';
import {
  q3FreeMonthsInsuranceFootnotes,
  beyondPromoFootnotes,
  energyRefixFreeAbsFootnote,
} from '@constants/footnotes';
import { generateSequentialPromocode } from '@utils/generateSequentialPromocode';

export const directPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const { isBeyond, isHomeRecover } = StoreService.load();
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return !isFlashSale && !isBeyond && !isHomeRecover;
    },
    endDate: new Date('2024-12-13T00:00:00'),
    startDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.TargetedOfferFourMonthsFree,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 4,
      codes: [...generateSequentialPromocode(Promo.Q4SAVE, 150), Promo.Q424BEYOND],
    },
  },
  {
    order: 1,
    validate: () => {
      const { isBeyond, isHomeRecover } = StoreService.load();
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return isFlashSale && !isBeyond && !isHomeRecover;
    },
    endDate: new Date('2024-12-13T00:00:00'),
    startDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.TargetedOfferSixMonthsFree,
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
      codes: [...generateSequentialPromocode(Promo.Q4SAVE, 150), Promo.Q424BEYOND],
    },
  },
  {
    order: 2,
    startDate: new Date('2024-01-09T00:00Z'),
    name: PromoName.Q1AbsDirectOffer,
    appliesTo: [ProductName.ABS],
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: q1HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 3,
    name: PromoName.AbsBeyond,
    startDate: new Date('2023-11-30T00:00:00'),
    endDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondPromo,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 71.42,
      codes: [Promo.BEYOND],
    },
  },
  {
    order: 4,
    name: PromoName.EnergyRefixFreeABS,
    startDate: new Date('2024-08-28T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: energyRefixFreeAbsFootnote.energyRefixFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: energyRefixFreeAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.BeyondFreeAbs,
    startDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: q2HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.Beyond12MonthsHalfPrice,
    validate: () => {
      const store = StoreService.load();
      return store.isBeyond === true;
    },
    startDate: new Date('2024-05-14T00:00:00'),
    endDate: new Date('2024-09-23T00:00:00'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: beyondPromoFootnotes?.beyond12MonthsHalfPrice,
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
      codes: [Promo.BEYOND12HP],
    },
  },
];
