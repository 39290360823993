import { directPromos } from './direct';
import { indirectPromos } from './indirect';

import { PromoConfig } from '@entities/types';

const allPromos: PromoConfig[] = [...indirectPromos, ...directPromos];

export const getIndirectPromos = () => [...indirectPromos];
export const getDirectPromos = () => [...directPromos];
export const getAllPromos = () => [...allPromos];
